var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item" },
      [
        _c(
          "md-card",
          [
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v("Pick your Color"),
                    ]),
                    _c("md-button", [_vm._v("Default")]),
                    _c("md-button", { staticClass: "md-primary" }, [
                      _vm._v("Primary"),
                    ]),
                    _c("md-button", { staticClass: "md-info" }, [
                      _vm._v("Info"),
                    ]),
                    _c("md-button", { staticClass: "md-success" }, [
                      _vm._v("Success"),
                    ]),
                    _c("md-button", { staticClass: "md-warning" }, [
                      _vm._v("Warning"),
                    ]),
                    _c("md-button", { staticClass: "md-danger" }, [
                      _vm._v("Danger"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v("Buttons with Label"),
                    ]),
                    _c("md-button", [
                      _c(
                        "span",
                        { staticClass: "md-label" },
                        [
                          _c("md-icon", { staticClass: "material-icons" }, [
                            _vm._v("keyboard_arrow_left"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" Left "),
                    ]),
                    _c("md-button", [
                      _vm._v(" Right "),
                      _c(
                        "span",
                        { staticClass: "md-label md-label-right" },
                        [
                          _c("md-icon", { staticClass: "material-icons" }, [
                            _vm._v("keyboard_arrow_right"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c("md-button", { staticClass: "md-info" }, [
                      _c(
                        "span",
                        { staticClass: "md-label" },
                        [
                          _c("md-icon", { staticClass: "material-icons" }, [
                            _vm._v("priority_high"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" Info "),
                    ]),
                    _c("md-button", { staticClass: "md-success" }, [
                      _c(
                        "span",
                        { staticClass: "md-label" },
                        [
                          _c("md-icon", { staticClass: "material-icons" }, [
                            _vm._v("check"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" Success "),
                    ]),
                    _c(
                      "md-button",
                      { staticClass: "md-warning" },
                      [
                        _c("md-icon", { staticClass: "material-icons" }, [
                          _vm._v("warning"),
                        ]),
                        _vm._v(" Warning "),
                      ],
                      1
                    ),
                    _c(
                      "md-button",
                      { staticClass: "md-danger" },
                      [
                        _c("md-icon", { staticClass: "material-icons" }, [
                          _vm._v("close"),
                        ]),
                        _vm._v(" Danger "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v("Pick your Size"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "button-container" },
                      [
                        _c("md-button", { staticClass: "md-primary md-sm" }, [
                          _vm._v("Small"),
                        ]),
                        _c("md-button", { staticClass: "md-primary" }, [
                          _vm._v("Normal"),
                        ]),
                        _c("md-button", { staticClass: "md-primary md-lg" }, [
                          _vm._v("Large"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v("Pick your Style"),
                    ]),
                    _c("md-button", { staticClass: "md-primary" }, [
                      _vm._v("Default"),
                    ]),
                    _c("md-button", { staticClass: "md-primary md-round" }, [
                      _vm._v("Round"),
                    ]),
                    _c(
                      "md-button",
                      { staticClass: "md-primary" },
                      [
                        _c("md-icon", [_vm._v("favorite")]),
                        _vm._v(" With Icon"),
                      ],
                      1
                    ),
                    _c(
                      "md-button",
                      { staticClass: "md-primary md-just-icon md-round" },
                      [_c("md-icon", [_vm._v("favorite")])],
                      1
                    ),
                    _c("md-button", { staticClass: "md-primary md-simple" }, [
                      _vm._v("Simple"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "title" }, [_vm._v("Pagination")]),
                    _c("pagination", {
                      attrs: { "no-arrows": "", "page-count": 5 },
                      model: {
                        value: _vm.defaultPagination,
                        callback: function ($$v) {
                          _vm.defaultPagination = $$v
                        },
                        expression: "defaultPagination",
                      },
                    }),
                    _c("pagination", {
                      attrs: { "page-count": 3 },
                      model: {
                        value: _vm.infoPagination,
                        callback: function ($$v) {
                          _vm.infoPagination = $$v
                        },
                        expression: "infoPagination",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v("Button Group"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "md-group" },
                      [
                        _c("md-button", { staticClass: "md-info" }, [
                          _vm._v("Left"),
                        ]),
                        _c("md-button", { staticClass: "md-info" }, [
                          _vm._v("Middle"),
                        ]),
                        _c("md-button", { staticClass: "md-info" }, [
                          _vm._v("Right"),
                        ]),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "md-group" },
                      [
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("1"),
                        ]),
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("2"),
                        ]),
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("3"),
                        ]),
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("4"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "md-group" },
                      [
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("5"),
                        ]),
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("6"),
                        ]),
                        _c("md-button", { staticClass: "md-round md-info" }, [
                          _vm._v("7"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c("div", { staticClass: "md-layout-item" }, [
                  _c("h4", { staticClass: "title" }, [
                    _vm._v("Social buttons"),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "social-buttons" },
                _vm._l(_vm.socialButtons, function (item) {
                  return _c(
                    "div",
                    { key: item.button, staticClass: "md-layout" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-25 md-xsmall-size-100 md-medium-size-40",
                        },
                        [
                          _c("p", { staticClass: "category" }, [
                            _vm._v("Default"),
                          ]),
                          _c("md-button", { class: `${item.button}` }, [
                            _c("i", { class: `${item.icon}` }),
                            _vm._v(" " + _vm._s(item.name) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-10 md-xsmall-size-100 md-medium-size-15",
                        },
                        [
                          _c("p", { staticClass: "category" }, [_vm._v(" ")]),
                          _c(
                            "md-button",
                            { class: `md-just-icon ${item.button}` },
                            [_c("i", { class: `${item.icon}` })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-10 md-xsmall-size-100 md-medium-size-15",
                        },
                        [
                          _c("p", { staticClass: "category" }, [_vm._v(" ")]),
                          _c(
                            "md-button",
                            { class: `md-round md-just-icon ${item.button}` },
                            [_c("i", { class: `${item.icon}` })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-10 md-xsmall-size-100 md-medium-size-35",
                        },
                        [
                          _c("p", { staticClass: "category" }, [
                            _vm._v("Neutral"),
                          ]),
                          _c(
                            "md-button",
                            { class: `md-simple md-just-icon ${item.button}` },
                            [_c("i", { class: `${item.icon}` })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-size-25 md-xsmall-size-100",
                        },
                        [
                          _c("p", { staticClass: "category" }, [_vm._v(" ")]),
                          _c(
                            "md-button",
                            { class: `md-simple ${item.button}` },
                            [
                              _c("i", { class: `${item.icon}` }),
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }